<template>
  <div>
    <results />
  </div>
</template>

<script>
import Results from '@/components/catalog/Results/Results.vue'

export default {
  components: { Results },
  name: 'Result',
}
</script>
