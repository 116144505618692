<template>
  <v-alert :type="type" v-model="alert" class="absolute mt-2" dense>
    <v-row align="center">
      <v-col class="grow">
        <p class="text-subtitle-1 mb-n1 font-weight-bold">{{ title }}</p>
        <p class="text-subtitle-2 mb-n1">{{ text }}</p>
      </v-col>
      <v-col class="shrink">
        <v-btn icon class="mr-n3 mt-n5" @click="dissmiss"
          ><v-icon x-small>fa-times</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertsCli',
  computed: {
    alert: function () {
      return this.$store.state.alerts.isActive
    },
    title: function () {
      return this.$store.state.alerts.title
    },
    text: function () {
      return this.$store.state.alerts.text
    },
    type: function () {
      return this.$store.state.alerts.type
    },
  },
  methods: {
    dissmiss: function () {
      this.$store.dispatch('alerts/desactiveAlerts')
    },
  },
}
</script>
